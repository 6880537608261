import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes } from 'react-router-dom';
import Hotjar from '@hotjar/browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import mixpanel from 'mixpanel-browser';
import { ToastContainer } from 'react-toastify';
import { initAOS } from './utils/aos-init';
import LandingPage from './pages/LandingPage';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Partners from './pages/Partners';
import Blog from './pages/Blog';
import Shop from './pages/Shop2';
import Shop2 from './pages/Shop';
import Cart from './pages/cart/Cart';
import LipawordCheckups from './pages/blog-pages/LipaworldCheckups';
import VentureCrush from './pages/blog-pages/VentureCrush';
import Startup302 from './pages/blog-pages/Startup302';
import Fathersday from './pages/blog-pages/FathersDay';
import SignupForm from './pages/SignUp';
import SigninForm from './pages/SignIn';
import EmailVerification from './pages/EmailVerification';
import PhoneNumber from './pages/PhoneNumber';
import AddPhoneNumber from './pages/AddPhoneNumber';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import NewPassword from './pages/NewPassword';
import PasswordResetConfirm from './pages/PasswordResetConfirm';
import PhoneVerification from './pages/PhoneVerification';
import MarketPlace from './pages/MarketPlace';
import Product from './pages/views/Product';
import Review from './pages/views/Review';
import Brand from './pages/views/Brand';
import CartComponent from './pages/cart-market/cart';
import Recipient from './pages/cart-market/recipient';
import Payment from './pages/cart-market/payment';
import Success from './pages/cart-market/success';
import Fail from './pages/cart-market/fail';
import Search from './pages/Search';
import Empty from './pages/cart-market/empty';
import ComingSoon from './pages/ComingSoon';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import AccountOverview from './pages/profile/AccountOverview';
import ProfileInformation from './pages/profile/PersonalInfo';
import SecuritySettings from './pages/profile/SecuritySettings';
import RecipientManagement from './pages/profile/RecipientManagement';
import VoucherManagement from './pages/profile/VoucherManagement';
import Contact2 from './pages/profile/Contact';
import Completion from './pages/cart-market/completion';
import Vouchers from './pages/cart-market/vouchers';
import 'react-toastify/dist/ReactToastify.css';
import {
  CartProvider,
  CountriesProvider,
  MerchantsProvider,
  RecipientsProvider,
  AuthProvider,
  TransactionProvider,
  ListsProvider,
  SelectedCountryProvider,
} from './context/index';

const App = () => {
  useEffect(() => {
    initAOS();

    // Clarity Script
    const clarityScript = document.createElement('script');
    clarityScript.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${process.env.REACT_APP_GTM}");
    `;
    document.head.appendChild(clarityScript);

    // LinkedIn Partner Script
    const linkedInPartnerScript = document.createElement('script');
    linkedInPartnerScript.innerHTML = `
      (function() {
        var _linkedin_partner_id = "AQWvFe65p8ioBHGU10U-whvb-BvlSOdMJO7I5xMb8_023P-M_oBiBv9r6gb4MrgAC4hn0knZ7BRTQIfNPg70_oAPUA8s0wSi4PLht3VzRBmxe4iEOsVcUQqV9ynMXAl50EyuraijfkraNWU5TpGABZeXXYLLGdGFE7Sd-0R_wqBghSlM2ea6mj1zgWdrculIl8lJL1YF10OlWcK2RzN2TQsegfujEGVqHbaKwTU6g30ndLrtyFy9PpelMdVxGHkdMvrHg_5Pj_T3dBnqqGBf2alUQxruP5P_AJnRa7iM_AFDPmIiRlC321c23lXlgO8KjcVeU2VG-frGkOohczRdQ5LjV9ifqg";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      })();
    `;
    document.head.appendChild(linkedInPartnerScript);

    // LinkedIn Insight Script
    const linkedInInsightScript = document.createElement('script');
    linkedInInsightScript.innerHTML = `
      (function() {
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })();
    `;
    document.head.appendChild(linkedInInsightScript);

    // Cleanup
    return () => {
      document.head.removeChild(clarityScript);
      document.head.removeChild(linkedInPartnerScript);
      document.head.removeChild(linkedInInsightScript);
    };
  }, []);

  // if (typeof window !== "undefined") {
  //   const siteId = 3514117;
  //   const hotjarVersion = 6;
  //   Hotjar.init(siteId, hotjarVersion);
  // }
  if (typeof window !== 'undefined' && process.env.REACT_APP_HOTJAR_ID) {
    const siteId = Number(process.env.REACT_APP_HOTJAR_ID);
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);

    if (process.env.REACT_APP_MIXPANEL_KEY) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
        debug: false,
        track_pageview: true,
        persistence: 'localStorage',
      });

      mixpanel.track('App Mounted', {
        'Event Type': 'Entry page',
      });
    }
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  });

  ReactGA.initialize(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID);

  return (
    <>
      <noscript>
        <img
          height="1"
          width="1"
          src="https://www.facebook.com/tr?id=1181109959982531&ev=PageView&noscript=1"
          alt="facebook pixel"
        />
      </noscript>

      <script id="clarity-script" strategy="afterInteractive">
        {`
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", ${process.env.REACT_APP_GTM});
        `}
      </script>

      <script id="gtm" src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM}`} />
      <script
        id="gtm2"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','${process.env.REACT_APP_GTM}');
				`,
        }}
      />

      <script type="text/javascript" id="hs-script-loader" async defer src={process.env.REACT_APP_HUBSPOT_CHAT} />
      <script
        id="linkedin-partner-id"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function() {
              var _linkedin_partner_id = "AQWvFe65p8ioBHGU10U-whvb-BvlSOdMJO7I5xMb8_023P-M_oBiBv9r6gb4MrgAC4hn0knZ7BRTQIfNPg70_oAPUA8s0wSi4PLht3VzRBmxe4iEOsVcUQqV9ynMXAl50EyuraijfkraNWU5TpGABZeXXYLLGdGFE7Sd-0R_wqBghSlM2ea6mj1zgWdrculIl8lJL1YF10OlWcK2RzN2TQsegfujEGVqHbaKwTU6g30ndLrtyFy9PpelMdVxGHkdMvrHg_5Pj_T3dBnqqGBf2alUQxruP5P_AJnRa7iM_AFDPmIiRlC321c23lXlgO8KjcVeU2VG-frGkOohczRdQ5LjV9ifqg";
              window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
              window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            })();
          `,
        }}
      />

      <script
        id="linkedin-insight"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function() {
              var s = document.getElementsByTagName("script")[0];
              var b = document.createElement("script");
              b.type = "text/javascript";
              b.async = true;
              b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
              s.parentNode.insertBefore(b, s);
            })();
          `,
        }}
      />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <SelectedCountryProvider>
            <CountriesProvider>
              <MerchantsProvider>
                <RecipientsProvider>
                  <ListsProvider>
                    <TransactionProvider>
                      <CartProvider>
                        <Routes>
                          <Route path="/" element={<LandingPage />} />
                          <Route path="/about-us" element={<AboutUs />} />
                          <Route path="/contact-us" element={<ContactUs />} />
                          <Route path="/partners" element={<Partners />} />
                          <Route path="/blog" element={<Blog />} />
                          <Route path="/shop-old" element={<Shop2 />} />
                          <Route path="/shop" element={<Shop />} />
                          <Route path="/cart" element={<Cart />} />
                          <Route path="/blog/Lipaworld-partners-with-CheckUps" element={<LipawordCheckups />} />
                          <Route
                            path="/blog/Lipaworld-Accepted-into-Prestigious-VentureCrushFG-Pod-Program"
                            element={<VentureCrush />}
                          />
                          <Route path="/blog/5-Thoughtful-Gift-Ideas-for-Fathers-Day" element={<Fathersday />} />
                          <Route
                            path="/blog/lipaworld-secures-first-place-in-fintech-category-at-startup-302-pitch-competition"
                            element={<Startup302 />}
                          />
                          <Route path="/signup" element={<SignupForm />} />
                          <Route path="/signin" element={<SigninForm />} />
                          <Route path="/emailverify" element={<EmailVerification />} />
                          <Route path="/phoneverify" element={<PhoneVerification />} />
                          <Route path="/phonenumber" element={<PhoneNumber />} />
                          <Route path="/addphonenumber" element={<AddPhoneNumber />} />
                          <Route path="/forgot-password" element={<ForgotPassword />} />
                          <Route path="/reset" element={<PasswordReset />} />
                          <Route path="/new-password" element={<NewPassword />} />
                          <Route path="/reset-confirmation" element={<PasswordResetConfirm />} />
                          <Route path="/marketplace" element={<MarketPlace />} />
                          <Route path="/product" element={<Product />} />
                          <Route path="/recent" element={<Review />} />
                          <Route path="/brands" element={<Brand />} />
                          <Route path="/checkout" element={<CartComponent />} />
                          <Route path="/recipient" element={<Recipient />} />
                          <Route path="/payment" element={<Payment />} />
                          <Route path="/success" element={<Success />} />
                          <Route path="/fail" element={<Fail />} />
                          <Route path="/search" element={<Search />} />
                          <Route path="/empty" element={<Empty />} />
                          <Route path="/coming-soon" element={<ComingSoon />} />
                          <Route path="/terms-of-use" element={<TermsOfUse />} />
                          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                          <Route path="/account-overview" element={<AccountOverview />} />
                          <Route path="/profile-information" element={<ProfileInformation />} />
                          <Route path="/security-settings" element={<SecuritySettings />} />
                          <Route path="/recipient-management" element={<RecipientManagement />} />
                          <Route path="/voucher-management" element={<VoucherManagement />} />
                          <Route path="/contact" element={<Contact2 />} />
                          <Route path="/completion" element={<Completion />} />
                          <Route path="/vouchers" element={<Vouchers />} />
                        </Routes>
                      </CartProvider>
                    </TransactionProvider>
                  </ListsProvider>
                </RecipientsProvider>
              </MerchantsProvider>
            </CountriesProvider>
          </SelectedCountryProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthProvider>
      <ToastContainer />
    </>
  );
};

export default App;

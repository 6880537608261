import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import Sidebar from '../sideBar';
import styles from './vouchers.module.css';
import { useAuth } from '../../../context';
import axios from 'axios';

const VoucherManagement = () => {
  const { authState } = useAuth();
  const [activeTab, setActiveTab] = useState('My vouchers');
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const vouchersPerPage = 10;

  const { data: vouchers = [], isLoading: loading } = useQuery({
    queryKey: ['vouchers', authState?.id],
    queryFn: async () => {
      if (!authState?.id) return [];
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_USERS_V1_URL}/transactions?buyerId=${authState.id}`,
        timeout: 30000,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return response.data.map((transaction) => ({
        id: transaction.transactionId,
        type: transaction.cartItem?.deal?.voucherName || '-',
        status: transaction.status || '-',
        recipient: transaction.cartItem?.productRecipient
          ? `${transaction.cartItem.productRecipient.name || ''} ${
              transaction.cartItem.productRecipient.surname || ''
            }`.trim()
          : 'Unknown Recipient',
        date: transaction.datePurchased ? new Date(transaction.datePurchased).toLocaleDateString() : '-',
        cartItem: transaction.cartItem,
        buyerName: transaction.buyerName,
        voucherCode:
          transaction.cartItem?.voucherCode || transaction.cartItem?.redemptionCode || transaction.voucherCode || '-',
        fullDetails: transaction,
      }));
    },
    enabled: !!authState?.id,
  });

  const filteredVouchers = useMemo(() => {
    if (!Array.isArray(vouchers)) return [];

    const searchValue = searchTerm.toLowerCase().trim();
    return vouchers.filter((voucher) => {
      if (!searchValue) return true;
      return (
        voucher.type.toLowerCase().includes(searchValue) ||
        voucher.recipient.toLowerCase().includes(searchValue) ||
        (voucher.voucherCode && voucher.voucherCode.toLowerCase().includes(searchValue))
      );
    });
  }, [vouchers, searchTerm]);

  const paginatedVouchers = useMemo(() => {
    const indexOfLastVoucher = currentPage * vouchersPerPage;
    const indexOfFirstVoucher = indexOfLastVoucher - vouchersPerPage;
    return filteredVouchers.slice(indexOfFirstVoucher, indexOfLastVoucher);
  }, [filteredVouchers, currentPage, vouchersPerPage]);

  const totalPages = useMemo(() => {
    return Math.ceil(filteredVouchers.length / vouchersPerPage);
  }, [filteredVouchers, vouchersPerPage]);

  const changePage = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const Pagination = () => {
    if (totalPages <= 1) return null;

    return (
      <div className={styles.pagination}>
        {currentPage > 1 && (
          <button className={styles.pageButton} onClick={() => changePage(currentPage - 1)}>
            ←
          </button>
        )}

        {[...Array(totalPages)].map((_, index) => {
          const pageNum = index + 1;
          if (pageNum === 1 || pageNum === totalPages || (pageNum >= currentPage - 1 && pageNum <= currentPage + 1)) {
            return (
              <button
                key={pageNum}
                className={`${styles.pageButton} ${currentPage === pageNum ? styles.activePage : ''}`}
                onClick={() => changePage(pageNum)}
              >
                {pageNum}
              </button>
            );
          } else if (pageNum === currentPage - 2 || pageNum === currentPage + 2) {
            return <span key={pageNum}>...</span>;
          }
          return null;
        })}

        {currentPage < totalPages && (
          <button className={styles.pageButton} onClick={() => changePage(currentPage + 1)}>
            →
          </button>
        )}
      </div>
    );
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleShare = async (voucher) => {
    const whatsappUrl = `https://wa.me/?text=Here's your voucher details:%0A${voucher.type}%0AStatus: ${voucher.status}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleDownload = (voucher) => {
    const voucherData = JSON.stringify(voucher.fullDetails, null, 2);
    const blob = new Blob([voucherData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `voucher-${voucher.id}.json`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleDropdown = (id) => {
    // event.stopPropagation();
    // setShowDropdown((prevId) => (prevId === id ? null : id));
    setShowDropdown(id);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`.${styles.voucherDropdownContainer}`)) {
        setShowDropdown(null);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const ViewModal = () => {
    const voucher = selectedVoucher?.fullDetails;
    if (!voucher) return null;

    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalTitle}>Voucher Information</h2>
              <p className={styles.modalSubtitle}>View your full voucher information</p>
            </div>
            <button className={styles.modalClose} onClick={() => setShowViewModal(false)}>
              ×
            </button>
          </div>

          <div className={styles.modalBody}>
            {/* <div className={styles.modalActions}>
                            <button className={styles.actionButton}>
                                <span>↗️</span> Redeem voucher
                            </button>
                            <button className={styles.moreButton}>⋮</button>
                        </div> */}

            <div className={styles.voucherStatusSection}>
              {/* <h3>Voucher Status</h3>
                            <div className={styles.statusGrid}>
                                <div className={styles.statusItem}>
                                    <span className={styles.label}>Status</span>
                                    <span className={`${styles.voucherStatus} ${styles.active}`}>Active</span>
                                </div>
                                <div className={styles.statusItem}>
                                    <span className={styles.label}>Exp date</span>
                                    <span>12 Nov 2024</span>
                                </div>
                            </div> */}

              {/* <div className={styles.balanceSection}>
                                <span className={styles.label}>Balance</span>
                                <div className={styles.balanceBar}>
                                    <div className={styles.balanceProgress} style={{width: '45%'}}></div>
                                </div>
                                <div className={styles.balanceValues}>
                                    <span>USD 0</span>
                                    <span>USD 25</span>
                                    <span>USD 55</span>
                                </div>
                            </div> */}
            </div>

            <div className={styles.voucherDetailsSection}>
              <h3>Voucher Details</h3>
              <div className={styles.detailsGrid}>
                <div className={styles.detailItem}>
                  <span className={styles.label}>Amount</span>
                  <span>{voucher.cartItem?.deal?.usdAmount}</span>
                </div>
                <div className={styles.detailItem}>
                  <span className={styles.label}>Brand</span>
                  <span>{voucher.cartItem?.deal?.merchantName}</span>
                </div>
                <div className={styles.detailItem}>
                  <span className={styles.label}>Voucher Code</span>
                  <span className={styles.copyable}>
                    {voucher.voucherCode ? (
                      <p className={styles.voucherCode}>{voucher.voucherCode}</p>
                    ) : (
                      <p>No voucher code available</p>
                    )}{' '}
                    <span className={styles.copyIcon}>
                      <img src="/img/profile/copy.svg" />
                    </span>
                  </span>
                </div>
                {/* <div className={styles.detailItem}>
                                    <span className={styles.label}>Order ID</span>
                                    <span className={styles.copyable}>{voucher.cartItem.deal.dealId} <span className={styles.copyIcon}>📋</span></span>
                                </div> */}
                <div className={styles.detailItem}>
                  <span className={styles.label}>Sender Name</span>
                  <span className={styles.nameTag}>{voucher.buyerName}</span>
                </div>
                <div className={styles.detailItem}>
                  <span className={styles.label}>Recipient Name</span>
                  <span className={styles.nameTag}>{voucher.cartItem.productRecipient.name}</span>
                </div>
                <div className={styles.detailItem}>
                  <span className={styles.label}>Recipient Number</span>
                  <span>{voucher.cartItem.productRecipient.mobileNumber}</span>
                </div>
                <div className={styles.detailItem}>
                  <span className={styles.label}>Date</span>
                  <span>{voucher.cartItem.productRecipient.datePurchased}</span>
                </div>
              </div>
            </div>

            <div className={styles.additionalNotesSection}>
              <h3>Additional Notes</h3>
              <p>{voucher.cartItem.deal.redemptionInstructions}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RedeemModal = () => {
    const voucher = selectedVoucher?.fullDetails;
    if (!voucher) {
      return null;
    }

    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalTitle}>Voucher Details</h2>
              <p className={styles.modalSubtitle}>Access your voucher code and redemption details</p>
            </div>
            <button className={styles.modalClose} onClick={() => setShowRedeemModal(false)}>
              x
            </button>
          </div>

          <div className={styles.modalBody}>
            <div className={styles.voucherCodeSection}>
              <h3 className={styles.sectionTitle}>Voucher code</h3>
              <p className={styles.voucherCode}>
                {voucher.voucherCode ? (
                  <p className={styles.voucherCode}>{voucher.voucherCode}</p>
                ) : (
                  <p>No voucher code available</p>
                )}
              </p>
            </div>

            <div className={styles.redemptionSection}>
              <h3 className={styles.sectionTitle}>Redemption details</h3>
              <p className={styles.redemptionDetails}>{voucher.cartItem.deal.redemptionInstructions}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleViewClick = (voucher) => {
    setSelectedVoucher({ fullDetails: voucher });
    setShowViewModal(true);
    setShowDropdown(null);
  };

  const handleRedeemClick = (voucher) => {
    setSelectedVoucher({ fullDetails: voucher });
    setShowRedeemModal(true);
    setShowDropdown(null);
  };

  // const handleDeleteVoucher = async () => {
  //     const token = localStorage.getItem("token");
  //     try {
  //       await axios.delete(
  //         `${process.env.REACT_APP_API_RECIPIENTS_URL}/transactions?id=${vouchers.id}`,
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         }
  //       );
  //       fetchRecipients();
  //     } catch (err) {
  //       setError("Failed to delete voucher");
  //     }
  //   };

  return (
    <div className={styles.voucherContainer}>
      <div className={styles.voucherTabs}>
        <button
          className={`${styles.voucherTab} ${activeTab === 'My vouchers' ? styles.activeVoucherTab : ''}`}
          onClick={() => setActiveTab('My vouchers')}
        >
          My vouchers
        </button>
      </div>

      <div className={styles.voucherHeader}>
        <div className={styles.voucherCount}>
          Vouchers <span className={styles.voucherCountBadge}>({vouchers.length})</span>
        </div>
        {/* <div className={styles.searchBox}>
          <input
            type="text"
            placeholder="Search voucher"
            className={styles.voucherSearch}
            value={searchTerm}
            onChange={handleSearch}
          />
          <span className={styles.voucherSearchIcon}>
            <img src="/img/profile/search-normal.svg" />
          </span>
        </div> */}
      </div>

      <div className={styles.vouchersList}>
        {loading && <div className={styles.loading}>Loading vouchers...</div>}
        {/* {error && <div className={styles.error}>{error}</div>} */}
        {!loading && paginatedVouchers.length === 0 && (
          <div className={styles.noResults}>
            {searchTerm ? `No vouchers found matching "${searchTerm}"` : 'No vouchers available'}
          </div>
        )}
        {!loading &&
          paginatedVouchers.map((voucher) => (
            <div key={voucher.id} className={styles.voucherCard}>
              <div className={styles.voucherInfo}>
                <div className={styles.voucherLogo}>
                  <div className={styles.mtnLogo}>
                    <img src={voucher.cartItem?.deal?.voucherImageUrl} />
                  </div>
                </div>
                <div className={styles.voucherDetails}>
                  <div className={styles.voucherTypeRow}>
                    <h3 className={styles.voucherType}>{voucher.type}</h3>
                    <span className={`${styles.voucherStatus} ${styles[voucher.status.toLowerCase()]}`}>
                      {voucher.status}
                    </span>
                  </div>
                  <div className={styles.voucherRecipient}>
                    <span>To: {voucher.recipient}</span>
                    <span className={styles.voucherDate}>{voucher.date}</span>
                  </div>
                </div>
              </div>

              <div className={styles.voucherActions}>
                <button className={styles.redeemButton} onClick={() => handleRedeemClick(voucher)}>
                  <span className={styles.downloadIcon}>
                    <img src="/img/profile/receive-square-2.svg" />
                  </span>
                  View
                </button>
                <button className={styles.redeemButton} onClick={() => handleShare(voucher)}>
                  <span>
                    <img src="/img/profile/share.svg" />
                  </span>
                  Share
                </button>

                {/* <div className={styles.voucherDropdownContainer}>
                  <button className={styles.voucherMoreButton} onClick={() => handleDropdown(voucher.id)}>
                    ⋮
                  </button>
                  {showDropdown === voucher.id && (
                    <div className={styles.voucherDropdownMenu}>
                      <button className={styles.voucherDropdownItem} onClick={() => handleViewClick(voucher)}>
                        <span>
                          <img src="/img/profile/frame.svg" />
                        </span>{' '}
                        View
                      </button>
                      <button className={styles.voucherDropdownItem} onClick={() => handleShare(voucher)}>
                        <span>
                          <img src="/img/profile/share.svg" />
                        </span>{' '}
                        Share
                      </button>
                      {/* <button
                        className={styles.voucherDropdownItem}
                        onClick={() => handleDownload(voucher)}
                      >
                        <span>
                          <img src="/img/profile/import.svg" />
                        </span>{" "}
                        Download
                      </button> 
                       <button className={styles.voucherDropdownItem}>
                                            <span><img src='/img/profile/trash1.svg'/></span> Delete
                                        </button> 
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          ))}
        <Pagination />
      </div>

      {showViewModal && <ViewModal />}
      {showRedeemModal && <RedeemModal />}
    </div>
  );
};

const Voucher = () => {
  return (
    <div className={styles.container}>
      <Sidebar />
      <main className={styles.mainContent}>
        <h1 className={styles.h1}>Voucher Management</h1>
        <div className={styles.grid}>
          <VoucherManagement />
        </div>
      </main>
    </div>
  );
};

export default Voucher;
